.greenBackground {
    background-color: forestgreen !important;
}

.redBackground {
    background-color: indianred !important;
}

.TableCard {
    margin: 10px;
}