body {
    margin: 0;
    /*padding-top: 61px;*/
    /*padding-bottom: 35px;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: white !important;
}

html, body, #root {
    height: 100%;
}
html {
    overflow-y: auto;
}


.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}
