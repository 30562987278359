.PaddingButton {
    margin-top: 1em;
}

.NoMarginBottom {
    margin-bottom: 0px !important;
}

.CheckboxStyle {
    margin-right: 1em;
}

a {
    font-color: #6c757d;
}

.oderLine {
    line-height: 35px;
}

.ErrorList {
    display: block;
    list-style-type: circle;
    margin-block-start: 0em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}

.ErrorMessage {
    color: #dc3545;
}

.noPadding {
    padding-left: 0 !important;
}

.noMargin {
    margin-left: 0 !important;
}

.datetimePickerStyled {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    /* border: thin solid gray; */
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 100%;
}

.datetimePickerStyled div.react-datetime-picker__wrapper {
    border: none;
}

.datetimePickerStyled.react-datetime-picker__inputGroup {
    padding: .375rem .75rem;
}