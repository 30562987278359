.ListCard {
    margin-bottom: 10px !important;
}

.ListCardBody {
    padding: 10px !important;
}

.titleMarginBottom {
    margin-bottom: 20px;
}