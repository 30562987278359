.Box {
    padding-bottom: 20px;
    padding-top: 20px;
    /*min-height: 300px;*/
}

.BoxContainer {
    padding: 0;
    margin: 0;
    /*margin-bottom: 130px;*/
}

.Success {
    background-color: #6ec382;
    color: white;
}

.Inactive {
    background-color: #E7E7E9;
}
