.CheckboxStyle {
    margin-top: 6px;
    margin-right: 10px;
}

.CheckboxLabel {
    /*font-size: 0.7rem; */
    margin-left: 1rem;
}

.CheckboxInput {
    margin-right: 10px;
}