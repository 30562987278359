.ImageContainer {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
}

.Logo {
    /*max-height: 150px;*/
    width: 35%;
}