
.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}

.ButtonMargin {
    margin-right:1em;
}

.MenuHeading {
    maring-top:0;
    margin-bottom: 1em;
}