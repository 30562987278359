.form-control {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
}

.form-control:focus {
    background-color: transparent;
    outline: none;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    box-shadow: none;
}