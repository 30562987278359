.AdCard {
    margin-bottom: 1em !important;
}

.CardBody {
    padding: 0.75em !important;
}

.fadeOut {
    opacity: 0;
    width: 0px;
    height: 0px;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}

.fadeIn {
    opacity: 1;
    width: 100%;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}