.App {
}

p {
    padding-left: 20px;
    padding-right: 20px;
}

.ImportantLink {
    color: dodgerblue !important;
}

.ImportantLinkDark {
    color: #6c757d !important;
}

.apiError {
    color: red !important;
}

.marginBottom {
    margin-bottom: 1em !important;
}

.jumbotron {
    padding: 1em 1em !important;
    margin-bottom: 1em !important;
    background-color: #e9ecef !important;
    border-radius: .3em !important;
}

.ErrorMessage {
    color: #dc3545;
}