.btn-primary {
    /*color: grey;*/
}

a {
    color: #6c757d !important;
}

.btn-secondary {
    background-color: #8B8B8B;
}

p {
    padding: 0;
    margin-bottom: 0.5em;
}

@import "css/formStyle.css";