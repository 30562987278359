.AdArea {
    background-color: white;
    color: #212529;
    height: 10px;
    padding-top: 10px;
}

.AdAreaSeparator {
    border: 1px solid #E7E7E9;
    height: 1px;
    margin-bottom: 10px;
}

.AdText {
    display: inline-flex;
    text-align: center;
    height: 80%;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
}
